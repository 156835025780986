import { useLocation } from 'react-router-dom'
import { useContext, useEffect } from 'react'
import { AppContext } from 'shared/helpers'
import posthog from 'posthog-js'

function usePosthogConfig() {
  const ctx = useContext(AppContext)
  if (ctx.appConfigLoading) return
  return ctx.posthog
}

function usePosthog(acceptTracking?: boolean) {
  const location = useLocation()
  const posthogConfig = usePosthogConfig()

  useEffect(() => {
    if (acceptTracking) {
      // Compensate for react-helmet lag when updating the page title
      // https://github.com/nfl/react-helmet/issues/189
      setTimeout(() => {
        console.log('$pageview', location.pathname, document.title)
        posthog.capture('$pageview')
      }, 1000)
    }
    return () => {
      if (acceptTracking) {
        posthog.capture('$pageleave', { $current_url: window.location.origin + location.pathname })
      }
    }
  }, [location, acceptTracking])

  useEffect(() => {
    if (posthogConfig?.apiKey && posthogConfig?.apiHost && acceptTracking) {
      posthog.init(posthogConfig.apiKey, {
        api_host: posthogConfig.apiHost,
        capture_pageview: false,
        capture_pageleave: false,
        autocapture: false,
        disable_session_recording: true,
        person_profiles: 'always',
      })
    }
  }, [posthogConfig, acceptTracking])
}

type DonationButtonClickedData = {
  campaignId: string
  location: string
}

type DonationSubmittedData = {
  donationAmountCents: number
  codonationAmountCents: number
  paymentMethod: string
  campaignId: string
  donationId: string
  platformNewsletterSelected: boolean
}

type DonationCompletedData = DonationSubmittedData

export function useAnalytics() {
  return {
    donationFormOpened: (campaignId: string) => posthog.capture('Donation Form Opened', { campaign_id: campaignId }),

    donationButtonClicked: ({ campaignId, location }: DonationButtonClickedData) =>
      posthog.capture('Donation Button Clicked', { campaign_id: campaignId, button_location: location }),

    donationSubmitted: ({
      donationAmountCents,
      codonationAmountCents,
      paymentMethod,
      campaignId,
      donationId,
      platformNewsletterSelected,
    }: DonationSubmittedData) =>
      posthog.capture('Donation Submitted', {
        donation_amount: donationAmountCents / 100,
        codonation_amount: codonationAmountCents / 100,
        payment_method: paymentMethod,
        campaign_id: campaignId,
        donation_id: donationId,
        platform_newsletter_selected: platformNewsletterSelected,
      }),

    donationCompleted: ({
      donationAmountCents,
      codonationAmountCents,
      paymentMethod,
      campaignId,
      donationId,
      platformNewsletterSelected,
    }: DonationCompletedData) => {
      const key = `donation-completed-${donationId}`

      // This event shouldn't be fired every time one's load a page
      if (!localStorage.getItem(key) && posthog.__loaded) {
        posthog.capture('Donation Completed', {
          donation_amount: donationAmountCents / 100,
          codonation_amount: codonationAmountCents / 100,
          payment_method: paymentMethod,
          campaign_id: campaignId,
          donation_id: donationId,
          platform_newsletter_selected: platformNewsletterSelected,
        })
        localStorage.setItem(key, 'true')
      }
    },

    codonationModalOpened: ({ campaignId }: { campaignId: string }) =>
      posthog.capture('Co-Donation Modal Opened', { campaign_id: campaignId }),

    codonationSetToZero: ({ campaignId }: { campaignId: string }) =>
      posthog.capture('Co-Donation Set To Zero', { campaign_id: campaignId }),

    donationAmountChanged: ({
      donationAmountCents,
      isCustomAmount,
      isDefaultSelection,
      campaignId,
    }: {
      donationAmountCents: number
      isCustomAmount: boolean
      isDefaultSelection: boolean
      campaignId: string
    }) =>
      posthog.capture('Donation Amount Changed', {
        new_donation_amount: donationAmountCents / 100,
        is_custom_amount: isCustomAmount,
        is_default_selection: isDefaultSelection,
        campaign_id: campaignId,
      }),
  }
}

export function useFeatureFlag() {
  // posthog.featureFlags.override({ 'new-suggested-amounts': 'v2-30D-50-100-200' })

  let newSuggestedAmountABC = 'control'

  if (posthog.getFeatureFlag('new-suggested-amounts') === 'v1-15-30D-50-100') {
    newSuggestedAmountABC = 'v1'
  }

  if (posthog.getFeatureFlag('new-suggested-amounts') === 'v2-30D-50-100-200') {
    newSuggestedAmountABC = 'v2'
  }

  return {
    newSuggestedAmountABC,
  }
}

export default usePosthog
